@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

/* Nav */
header {
  position: relative;
  box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}

.logo {
  max-width: 170px;
  margin-left: 8%;
  opacity: 0;
  animation: leftSideAni 1s ease forwards;
}

.logo img {
  width: 35%;
  height: auto;
  display: block;
}

.logo a {
  color: #000;
  text-decoration: none;
}

ul {
  display: flex;
  gap: 1rem;
  list-style-type: none;
  background-color: transparent;
  overflow: hidden;
  text-align: center;
}

li a {
  display: inline-block;
  padding: 0.3rem 1rem;
  color: var(--nav-text);
  text-decoration: none;
  font-size: 1.65rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  border-radius: 36px;
  line-height: 2;
  transition: 0.3s;
  opacity: 0;
  animation: navani 0.3s ease forwards;
  animation-delay: calc(0.15s * var(--navAni));
}
ul li a {
  position: relative;
}

ul li a::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 2rem);
  height: 3px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}
ul li a::before {
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

ul li a:hover::before {
  transform: scale(1, 1);
}

li a.active,
li a:hover {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#click {
  display: none;
}

.menu {
  display: none;
}

.bars {
  display: none;
}

/* Responsive */

/* Mobile */

@media (max-width: 767px) {
  .moon-icon {
    margin-left: 100%;
  }

  .bars {
    animation: rightSideAni 1s ease forwards;
    display: flex;
    order: -1;
    font-size: x-large;
  }

  .logo {
    margin-left: 5rem;
  }

  .navList {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 6.7rem; /* Ajustez la position du menu déroulant selon vos besoins */
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
    z-index: 2;
  }

  .navList.active {
    display: flex;
  }

  .navList li {
    display: block;
    text-align: center;
  }
}

@media (min-width: 599px) and (max-width: 767px) {
  .logo {
    margin-left: 35%;
  }

  .moon-icon {
    margin-left: 73%;
    font-size: x-large;
  }

  .bars {
    animation: rightSideAni 1s ease forwards;
    margin-left: 3%;
    display: flex;
    order: -1;
    font-size: x-large;
  }

  .txtNav {
    font-size: 2rem;
  }

  .navList {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 6.7rem; /* Ajustez la position du menu déroulant selon vos besoins */
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
    z-index: 2;
  }

  .dark-mode .navList {
    background-color: #0d1021;
  }

  .navList.active {
    display: flex;
  }

  .navList li {
    display: block;
    text-align: center;
  }
}

/* Tablette vertical */

@media (width: 768px) {
  .moon-icon {
    margin-left: 90%;
  }

  .bars {
    animation: rightSideAni 1s ease forwards;
    display: flex;
    order: -1;
    font-size: x-large;
  }

  .logo {
    margin-left: 35%;
  }

  .txtNav {
    font-size: 2rem;
  }

  .navList {
    display: none; /* Cacher le menu par défaut */
    flex-direction: column;
    position: absolute;
    top: 8rem;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
    z-index: 2;
  }

  .dark-mode .navList {
    background-color: #0d1021;
  }

  .navList.active {
    display: flex;
  }

  .navList li {
    display: block;
    text-align: center;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .moon-icon {
    margin-left: 100%;
  }

  .bars {
    animation: rightSideAni 1s ease forwards;
    display: flex;
    order: -1;
    font-size: x-large;
  }

  .logo {
    margin-left: 35%;
  }

  .txtNav {
    font-size: 2rem;
  }

  .navList {
    display: none; /* Cacher le menu par défaut */
    flex-direction: column;
    position: absolute;
    top: 6.7rem;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
    z-index: 2;
  }

  .dark-mode .navList {
    background-color: #0d1021;
  }

  .navList.active {
    display: flex;
  }

  .navList li {
    display: block;
    text-align: center;
  }
}

/* Tablette Horizontal */

@media (min-width: 1024px) and (max-width: 1599px) {
  .txtNav {
    font-size: 1.4rem;
  }

  .moon-icon {
    margin-right: 5rem;
    margin-left: 5rem;
  }

  .logo {
    margin-right: 0;
  }
}
