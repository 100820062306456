@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

:root {
  --primary-color: #f9532d;
  --primary-dark: #0d1021;
  --primary-font: "Outfit", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Outfit", sans-serif;
  overflow-x: hidden;
}
/* Genral */
.container {
  padding: 0.3rem 0;
}

.img-w {
  width: 45%;
}
.text-color {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mode input {
  display: none;
}
.dark-mode {
  background-color: var(--primary-dark);
  color: #ffffff;
}
.dark-mode .social a:hover {
  color: var(--primary-dark);
  background-color: var(--primary-color);
}

.dark-mode .logo a {
  color: #fff;
}

.dark-mode .detail button {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
}

.dark-mode header {
  box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
  background: var(--primary-dark);
}

.moon-icon {
  font-size: 1.4rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
  opacity: 0;
  animation: rightSideAni 1s ease forwards;
  animation-delay: 0.5s;
  margin-right: 10rem;
}

.mode i {
  font-size: 1.4rem;
  cursor: pointer;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: rightSideAni 1.5s ease forwards;
}

/* Animation */
@keyframes leftSideAni {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes navani {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes rightSideAni {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes topSideAni {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes SocialAni {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes btn-ani {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/* @keyframes ImgAni {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-9px);
    }
    100% {
        transform: translateX(0);
    }
} */
@keyframes imageAni {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 80%;
  }
  .container {
    padding: 0 4.4rem;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 80%;
  }
  .container {
    padding: 0 1.6rem;
  }
  .main {
    margin-top: 2rem;
    grid-template-columns: 1fr;
  }
  .images {
    width: 60%;
    margin: auto;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: 100%;
  }
  header {
    height: auto;
  }

  ul {
    background: #fff;
    width: 100%;
    height: 100vh;
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    top: 3.4rem;
    left: -100%;
    z-index: 111111;
  }

  .menu {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: #244d61;
  }

  #click:checked ~ ul {
    left: 0%;
    transition: all 0.3s ease;
  }

  .dark-mode .menu {
    background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
    -webkit-background-clip: text;
    background-clip: initial;
    -webkit-text-fill-color: transparent;
  }

  .dark-mode ul {
    background: var(--primary-dark);
  }

  .mode {
    position: absolute;
    right: 4rem;
  }

  .images {
    width: 88%;
    margin: auto;
  }
}
