@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

/* Section 1 */
.mode i {
  font-size: 1.4rem;
  cursor: pointer;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: rightSideAni 1.5s ease forwards;
}

.main {
  display: grid;
  grid-template-columns: 6% repeat(auto-fit, minmax(277px, 1fr));
  row-gap: 2rem;
  align-items: center;
}
.main h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  opacity: 0;
  animation: rightSideAni 1s ease forwards;
}
.main h1 {
  font-size: 3.5rem;
  font-weight: 600;
  opacity: 0;
  animation: topSideAni 1s ease forwards;
}

.main p {
  line-height: 1.7;
  font-size: 1.15rem;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.2s;
}

.btncv-socialAboutMe {
  margin-top: auto;
  display: flex;
  align-items: center;
}

.social {
  margin-top: auto;
  margin-left: 3%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
}

.social a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.5rem;
  border-radius: 60%;
  padding: 0.39rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.social a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.detailAbout {
  width: auto;
  max-height: 550px;
  overflow: auto;
}

body.scrollable {
  overflow: auto;
}

.underline-gradient1 {
  background-image: linear-gradient(
    100deg,
    #f7086c 0%,
    #ba00ff 50%,
    #35c9fc 100%
  );
  background-size: 57% 3px; /* Hauteur de votre soulignement */
  background-position: 50% 100%; /* Positionne le dégradé en bas */
  margin-top: 5px;
  padding-bottom: 5px;
  background-repeat: no-repeat;
}

.underline-gradient2 {
  background-image: linear-gradient(
    100deg,
    #f7086c 0%,
    #ba00ff 50%,
    #35c9fc 100%
  );
  background-size: 24% 3px; /* Hauteur de votre soulignement */
  background-position: 0 100%; /* Positionne le dégradé en bas */
  background-position-x: 50%;
  margin-top: 5px;
  padding-bottom: 5px;
  background-repeat: no-repeat;
}

.texte {
  text-align: left;
}

.txtAbout {
  margin-right: 5%;
}

.img-wAboutMe {
  width: 45%;
  margin-right: 40%;
}
.text-color {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.imagesAboutMe {
  width: 80%;
  margin-right: auto;
  position: relative;
  z-index: -1;
}
.imagesAboutMe {
  opacity: 0;
  animation: imageAni 1s ease forwards;
  animation-delay: 0.3s;
  position: relative;
}
.imagesAboutMe::before {
  content: "";
  position: absolute;
  margin-top: 15%;
  margin-right: 4%;
  width: 27vw;
  height: 21vh;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  border-radius: 200px;
  top: 22%;
  right: 22%;
}
.imagesAboutMe img {
  position: relative;
}

.main .btn {
  margin-left: 7%;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  margin-top: 2rem;
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}

/* Responsive */

/* Mobile  */

@media (width: 320px) {
  .main {
    height: 550px;
  }

  .img-secAboutMe {
    display: none;
  }

  .btncv-socialAboutMe {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
  }

  .detailAbout {
    width: auto;
    max-height: 360px;
    overflow: auto;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 230px 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 210px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 280px) and (max-width: 429px) {
  .main {
    height: 550px;
  }

  .img-secAboutMe {
    display: none;
  }

  .btncv-socialAboutMe {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }

  .detailAbout {
    width: auto;
    max-height: 360px;
    overflow: auto;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 230px 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 210px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

@media (width: 430px) {
  .main {
    height: 700px;
  }

  .btncv-socialAboutMe {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }

  .img-secAboutMe {
    display: none;
  }

  .underline-gradient1 {
    background-size: 190px 3px;
    background-position: 50% 100%;
  }

  .underline-gradient2 {
    background-size: 210px 3px;
    background-position: 50% 100%;
  }
}

@media (min-width: 431px) and (max-width: 767px) {
  .img-secAboutMe {
    display: none;
  }

  .btncv-socialAboutMe {
    margin-left: 5rem;
  }

  .detailAbout {
    width: auto;
    max-height: 535px;
    overflow: auto;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 230px 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 210px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .img-secAboutMe {
    display: none;
  }

  .btncv-socialAboutMe {
    margin-left: 5rem;
  }

  .detailAbout {
    width: auto;
    max-height: 480px;
    overflow: auto;
  }

  .underline-gradient1 {
    background-size: 350px 3px;
  }

  .underline-gradient2 {
    background-size: 210px 3px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .img-secAboutMe {
    display: none;
  }

  .detailAbout {
    max-height: 47.5rem;
  }

  .btncv-socialAboutMe {
    margin-left: 25%;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 380px 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 170px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

/* Tablette vertical */

@media (min-width: 768px) and (max-width: 1023px) {
  .img-secAboutMe {
    display: none;
  }

  .detailAbout {
    margin-left: 6%;
    max-height: 40rem;
  }

  .btncv-socialAboutMe {
    margin-left: 35%;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 30rem 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 170px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

/* Tablette Horizontal */

@media (min-width: 1024px) and (max-width: 1024px) {
  .img-secAboutMe {
    display: none;
  }

  .btncv-socialAboutMe {
    margin-left: 30%;
  }

  .img-secAboutMe {
    width: 57rem;
  }

  .img-wAboutMe {
    margin-right: 50rem;
  }

  .imagesAboutMe::before {
    width: 65%;
    right: 35%;
    margin-left: auto;
  }

  .detailAbout {
    max-height: 40rem;
    margin-top: 4rem;
  }

  .underline-gradient1 {
    background-size: 380px 3px;
  }

  .underline-gradient2 {
    background-size: 165px 3px;
  }
}

@media (min-width: 1025px) and (max-width: 1213px) {
  .img-secAboutMe {
    width: 50rem;
  }

  .img-wAboutMe {
    margin-right: 50rem;
  }

  .imagesAboutMe::before {
    width: 65%;
    right: 35%;
    margin-left: auto;
  }

  .detailAbout {
    max-height: 28rem;
  }
}

@media (min-width: 1117px) and (max-width: 1213px) {
  .detailAbout {
    max-height: 30rem;
  }
}

@media (min-width: 1214px) and (max-width: 1599px) {
  .img-secAboutMe {
    width: 50rem;
  }

  .imagesAboutMe::before {
    width: 65%;
    right: 25%;
    margin-left: auto;
  }

  .detailAbout {
    max-height: 35rem;
  }

  .underline-gradient1 {
    background-size: 470px 3px;
  }

  .btncv-socialAboutMe {
    margin-left: 35%;
  }
}

@media (min-width: 1215px) and (max-width: 1365px) {
  .detailAbout {
    max-height: 31rem;
  }
}

@media (min-width: 1366px) and (max-width: 1599px) {
  .detailAbout {
    max-height: 30rem;
  }

  .imagesAboutMe::before {
    width: 65%;
    right: 28%;
  }

  .underline-gradient1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 30rem 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-gradient2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 210px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }
}

/* Grand ecran  */

@media (min-width: 1780px) and (max-width: 1880px) {
  .detailAbout {
    max-height: 30rem;
  }
}
