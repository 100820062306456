@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

.img-wAbout {
  width: 45%;
  margin-right: 40%;
}

.imagesAbout {
  width: 80%;
  margin-right: auto;
  position: relative;
  z-index: -1;
}
.imagesAbout {
  opacity: 0;
  animation: imageAni 1s ease forwards;
  animation-delay: 0.3s;
  position: relative;
}
.imagesAbout::before {
  content: "";
  position: absolute;
  margin-top: 15%;
  margin-right: 4%;
  width: 27vw;
  height: 21vh;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  border-radius: 200px;
  top: 22%;
  right: 22%;
}
.imagesAbout img {
  position: relative;
}

.txtContact {
  display: flex;
  justify-content: space-between;
  margin-right: 25%;
}

.contactDetails {
  display: flex;
  flex-direction: column;
}

.formulaireContact,
.coordContact,
.btncv-social {
  width: 100%;
}

.txtCoordContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 53%;
}

.coord {
  margin-bottom: 10%;
}

.form-submitted-message {
  margin-left: auto;
  margin-top: 10%;
}

.titre .form-submitted-message {
  margin-left: auto;
}

.social {
  display: flex;
  justify-content: center;
  margin-top: 3%;
}

.social-btnContact {
  margin: 10px; /* Ajuster l'espacement entre les boutons */
}

.formulaireContact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  margin-top: 10%;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.form-group-message {
  text-align: left;
  height: 200px;
  overflow: none;
}

textarea {
  height: 80%; /* espace disponible dans .form-group-message */
}

label {
  margin-bottom: 5px;
}

input,
textarea {
  width: 125%;
  padding: 4px;
  margin: 8px 0;
  box-sizing: border-box;
}

.main .btn {
  margin-left: 7%;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  margin-top: 2rem;
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}

button {
  background: transparent;
}

/* Responsive */

/* Mobile */

@media (max-width: 767px) {
  .txtContact {
    flex-direction: column;
    margin-right: 0;
  }

  .txtCoordContact {
    margin-right: 0; /* Réinitialiser la marge pour prendre l'écran entier */
  }

  .formulaireContact {
    margin-top: 10%; /* Ajuster la marge pour un meilleur espacement */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coord h3 {
    margin-bottom: 0;
  }

  .formulaireContact h3 {
    margin-bottom: 0;
  }

  .socialContact {
    margin-top: -0.5rem;
    margin-right: 1rem;
  }

  .form-group {
    width: 160%;
    margin-left: -5rem;
  }

  .form-group-message {
    margin-left: -5rem;
  }

  textarea {
    width: 130%;
    height: 150px;
    box-sizing: border-box;
  }

  .detail {
    margin-bottom: 50px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  textarea {
    width: 152%;
    height: 150px;
    box-sizing: border-box;
  }

  .form-group {
    width: 195%;
    margin-left: -7rem;
  }

  .form-group-message {
    margin-left: -7rem;
  }

  .txtCoordContact {
    font-size: 1.3rem;
  }

  .coord h3 {
    font-size: 2rem;
  }

  .formulaireContact h3 {
    font-size: 2rem;
  }

  .form-group label {
    font-size: 1.3rem;
  }

  .form-group-message label {
    font-size: 1.3rem;
  }

  .formulaireContact button {
    font-size: 1.2rem;
  }

  .socialContact {
    margin-left: 5%;
  }
}

/* Tablette vertical */

@media (width: 768px) {
  .imagesAbout {
    display: none;
  }

  .img-wAbout {
    display: none;
  }

  .txtContact {
    flex-direction: column;
    margin-right: 0;
  }

  .txtCoordContact {
    margin-right: 0; /* Réinitialiser la marge pour prendre l'écran entier */
  }

  .formulaireContact {
    margin-top: 10%; /* Ajuster la marge pour un meilleur espacement */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coord h3 {
    margin-bottom: 0;
  }

  .formulaireContact h3 {
    margin-bottom: 0;
  }

  .socialContact {
    margin-top: -2rem;
    margin-right: 2rem;
  }

  .form-group {
    width: 255%;
    margin-left: -9rem;
  }

  .form-group-message {
    margin-left: -9rem;
  }

  textarea {
    width: 170%;
    height: 150px;
    box-sizing: border-box;
  }

  .coord {
    margin-bottom: 3.5rem;
  }

  .txtCoordContact {
    font-size: 1.4rem;
  }

  .coord h3 {
    font-size: 2.2rem;
  }

  .formulaireContact h3 {
    font-size: 2.2rem;
  }

  .form-group label {
    font-size: 1.4rem;
  }

  .form-group-message label {
    font-size: 1.4rem;
  }

  .formulaireContact button {
    font-size: 1.3rem;
  }

  .detail {
    margin-bottom: 50px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .imagesAbout {
    display: none;
  }

  .img-wAbout {
    display: none;
  }

  .txtContact {
    flex-direction: column;
    margin-right: 0;
  }

  .txtCoordContact {
    margin-right: 0; /* Réinitialiser la marge pour prendre l'écran entier */
  }

  .formulaireContact {
    margin-top: 10%; /* Ajuster la marge pour un meilleur espacement */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .coord h3 {
    margin-bottom: 0;
  }

  .formulaireContact h3 {
    margin-bottom: 0;
  }

  .socialContact {
    margin-top: -2rem;
    margin-right: 2rem;
  }

  .form-group {
    width: 255%;
    margin-left: -9rem;
  }

  .form-group-message {
    margin-left: -9rem;
  }

  textarea {
    width: 170%;
    height: 150px;
    box-sizing: border-box;
  }

  .coord {
    margin-bottom: 3.5rem;
  }

  .txtCoordContact {
    font-size: 1.4rem;
  }

  .coord h3 {
    font-size: 2.2rem;
  }

  .formulaireContact h3 {
    font-size: 2.2rem;
  }

  .form-group label {
    font-size: 1.4rem;
  }

  .form-group-message label {
    font-size: 1.4rem;
  }

  .formulaireContact button {
    font-size: 1.3rem;
  }
}

/* Tablette Horizontal */

@media (min-width: 1024px) and (max-width: 1024px) {
  .imagesAbout {
    display: none;
  }

  .img-wAbout {
    display: none;
  }

  .txtContact {
    margin-right: 0;
  }

  .socialContact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    margin-top: 3%;
  }

  .social-btnContact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 1025px) and (max-width: 1213px) {
  .imagesAbout {
    margin-left: 0.5rem;
    width: 35rem;
  }

  .img-wAbout {
    width: 20.5rem;
  }

  .socialContact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    margin-top: 3%;
  }

  .social-btnContact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 1214px) and (max-width: 1599px) {
  .txtContact {
    margin-top: 50px;
  }

  .imagesAbout {
    margin-left: -4.7rem;
    width: 39rem;
    left: -3%;
  }

  .img-wAbout {
    width: 20rem;
    margin-left: -2rem;
  }

  .socialContact {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-content: center;
    margin-top: 3%;
  }

  .social-btnContact {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
