@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

/* Section 1 */
.mode i {
  font-size: 1.4rem;
  cursor: pointer;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: rightSideAni 1.5s ease forwards;
}

.main {
  display: grid;
  grid-template-columns: 6% repeat(auto-fit, minmax(277px, 1fr));
  row-gap: 2rem;
  align-items: center;
}
.main h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  opacity: 0;
  animation: rightSideAni 1s ease forwards;
}
.main h1 {
  font-size: 3.5rem;
  font-weight: 600;
  opacity: 0;
  animation: topSideAni 1s ease forwards;
}

.main p {
  line-height: 1.7;
  font-size: 1.15rem;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.2s;
}

.btncv-social {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.social {
  margin-top: auto;
  margin-left: 3%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.4rem;
}

.social a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  border-radius: 60%;
  padding: 0.15rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.social a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.detail span {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.images {
  width: 80%;
  margin-left: auto;
  z-index: -1;
}

.images {
  opacity: 0;
  animation: imageAni 1s ease forwards;
  animation-delay: 0.3s;
  position: relative;
}

.images::before {
  content: "";
  position: absolute;
  margin-top: 15%;
  margin-right: 4%;
  width: 27vw;
  height: 21vh;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  border-radius: 200px;
  top: 22%;
  right: 22%;
}

.images img {
  position: relative;
}

.main .btn {
  margin-left: 7%;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  margin-top: 2rem;
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}
.detail button {
  display: inline-block;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  border: 0;
  color: #0d1021;
  background-color: #fff;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1.01rem;
  cursor: pointer;
  text-align: center;
}
.detail button:hover {
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  transition: all 0.3s ease-in-out;
}

/* Section 1 End */

/* Responsive */

/* Mobile */

@media (max-width: 767px) {
  .img-sec {
    display: none;
  }

  .btncv-social {
    flex-direction: column; /* Met les boutons en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    height: 10rem;
    margin-left: -1rem;
    margin-top: -1rem;
  }

  .btn {
    margin-bottom: 1rem;
  }

  .social {
    margin-left: 8%;
  }

  .homeMediumTittle {
    margin-top: 1rem;
  }
}

@media (width: 280px) {
  .detail h1 {
    font-size: 1.8rem;
  }

  .detail h3 {
    font-size: 1.1rem;
  }

  .detail p {
    font-size: 0.8rem;
  }

  .detail {
    margin-right: 15%;
  }
}

@media (min-width: 300px) and (max-width: 367px) {
  .detail h1 {
    font-size: 1.8rem;
  }

  .detail h3 {
    font-size: 1.3rem;
  }

  .detail p {
    font-size: 0.9rem;
  }
}

@media (min-width: 368px) and (max-width: 500px) {
  .detail h1 {
    font-size: 2rem;
  }

  .detail h3 {
    font-size: 1.5rem;
  }

  .detail p {
    font-size: 1rem;
  }
}

@media (min-width: 368px) and (max-width: 600px) {
  .detail h1 {
    font-size: 2rem;
  }

  .detail h3 {
    font-size: 1.5rem;
  }

  .detail p {
    font-size: 1rem;
  }
}

@media (min-width: 599px) and (max-width: 767px) {
  .btncv-social {
    height: 15rem;
  }
}

/* Tablette vertical */

@media (width: 768px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .detail h1 {
    font-size: 4rem;
  }

  .detail h3 {
    font-size: 2rem;
  }

  .detail p {
    font-size: 1.5rem;
  }

  .social {
    margin-left: 5%;
    gap: 1.5rem;
  }

  .btncv-social {
    margin-left: 15rem;
  }

  .images::before {
    margin-top: 15%;
    width: 30rem;
    top: 22%;
    right: 10%;
    width: 70%;
    margin-left: auto;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .social {
    margin-left: 5%;
    gap: 1.5rem;
  }

  .btncv-social {
    margin-left: 5rem;
  }

  .images::before {
    margin-top: 15%;
    width: 30rem;
    top: 22%;
    right: 26%;
    width: 60%;
    margin-left: auto;
  }
}

@media (min-width: 820px) and (max-width: 1023px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .detail h1 {
    font-size: 4rem;
  }

  .detail h3 {
    font-size: 2rem;
  }

  .detail p {
    font-size: 1.5rem;
  }

  .social {
    margin-left: 5%;
    gap: 1.5rem;
  }

  .btncv-social {
    margin-left: 25%;
  }

  .images::before {
    margin-top: 15%;
    width: 30rem;
    top: 22%;
    right: 19%;
    width: 80%;
    margin-left: auto;
  }
}

@media (width: 1024px) {
  .main {
    display: flex;
    flex-direction: column;
  }

  .detail h1 {
    font-size: 4rem;
  }

  .detail h3 {
    font-size: 2rem;
  }

  .detail p {
    font-size: 1.5rem;
  }

  .social {
    margin-left: 5%;
    gap: 1.5rem;
  }

  .btncv-social {
    margin-left: 30%;
  }

  .images::before {
    margin-top: 15%;
    width: 30rem;
    top: 22%;
    right: 19%;
    width: 80%;
    margin-left: auto;
  }
}

/* Tablette Horizontal */

@media (min-width: 1025px) and (max-width: 1599px) {
  .social {
    margin-left: 5%;
    gap: 1.5rem;
  }

  .btncv-social {
    margin-left: 5rem;
    align-items: end;
  }

  .images {
    width: 80%;
    margin-left: auto;
    z-index: -1;
  }

  .img-w {
    width: 70%;
  }

  .images::before {
    width: 85%;
    right: 14%;
    margin-left: auto;
  }
}

@media (min-width: 1225px) and (max-width: 1599px) {
  .img-w {
    width: 60%;
  }

  .btncv-social {
    margin-left: 7rem;
  }
}

@media (min-width: 1366px) and (max-width: 1599px) {
  .img-w {
    width: 55%;
  }

  .btncv-social {
    margin-left: 10rem;
  }

  .detail {
    margin-bottom: 50px;
  }
}

@media (min-width: 1425px) and (max-width: 1599px) {
  .img-w {
    width: 55%;
  }

  .btncv-social {
    margin-left: 10rem;
  }
}
