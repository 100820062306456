@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

.page {
  width: auto;
  max-height: 867px;
  overflow: auto;
}

.langages,
.outils {
  width: 100%;
  display: block;
}

.langages {
  margin-top: 30px;
  margin-bottom: 50px;
}

.titreSkills {
  margin-bottom: 25px;
}

.underline-skill1 {
  background-image: linear-gradient(
    100deg,
    #f7086c 0%,
    #ba00ff 50%,
    #35c9fc 100%
  );
  background-size: 230px 3px; /* Hauteur de votre soulignement */
  background-position: 50% 100%; /* Positionne le dégradé en bas */
  margin-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  background-repeat: no-repeat;
}

.underline-skill2 {
  background-image: linear-gradient(
    100deg,
    #f7086c 0%,
    #ba00ff 50%,
    #35c9fc 100%
  );
  background-size: 185px 3px; /* Hauteur de votre soulignement */
  background-position: 0 100%; /* Positionne le dégradé en bas */
  background-position-x: 50%;
  margin-top: 5px;
  margin-bottom: 25px;
  padding-bottom: 5px;
  background-repeat: no-repeat;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 80rem;
}

.cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: space-between;
}

.col {
  width: calc(33.33% - 2rem);
  margin: 1rem;
  cursor: pointer;
}

.containerCard {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.front,
.back {
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-position: center;
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  min-height: 280px;
  box-shadow: 0 0 10px solid blue;
  height: auto;
  border-radius: 10px;
  color: #fff;
  font-size: 1.5rem;
}

.back {
  background: #cedce7;
  background: -webkit-linear-gradient(
    100deg,
    #f7086c 10%,
    #ba00ff 50%,
    #35c9fc 90%
  );
  background: -o-linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  opacity: 0.6;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
  background-color: #080912;
}

.containerCard:hover .front,
.containerCard:hover .back {
  -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
}

.containerCard .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerCard .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerCard:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerCard:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.front .inner p {
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.front .inner p:after {
  content: "";
  width: 4rem;
  height: 2px;
  position: absolute;
  background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -0.75rem;
}

.front .inner span {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Montserrat";
  font-weight: 300;
}

.card {
  width: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  background-color: #ffffff;
}

.dark-mode .card:not(:hover) {
  background-color: #0d1021;
}

.innertxt {
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
}

.innertxtcss {
  margin-top: 10%;
}

.innertxtjs {
  margin-top: 7%;
}

.innertxtcplusplus {
  margin-top: 15%;
}

.innertxtcsharp {
  margin-top: 15%;
}

.innertxtreact {
  margin-top: 7%;
}

.innertxtjava {
  margin-top: 12%;
}

.innertxtphp {
  margin-top: 12%;
}

.innertxtsql {
  margin-bottom: 2%;
}

.innertxtvs {
  margin-top: 7%;
}

.innertxtvscode {
  margin-top: 10%;
}

.innertxtandroid {
  margin-top: 6%;
}

.innertxtfigma {
  margin-top: 7%;
}

.innertxtwhimsical {
  margin-top: 7%;
}

.innertxtgit {
  margin-top: 4%;
}

.innertxtmysql {
  margin-top: 1%;
  margin-bottom: 2%;
}

.innertxtphpadmin {
  margin-top: 1%;
  margin-bottom: 2%;
}

.innertxtqt {
  margin-top: 1%;
  margin-bottom: 2%;
}

.btnProjets {
  color: #080912;
}

.bot {
  margin-bottom: 40%;
}

.txtMobile {
  display: none;
}

@media screen and (max-width: 64rem) {
  .col {
    width: calc(33.333333% - 2rem);
  }
}

@media screen and (max-width: 48rem) {
  .col {
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 32rem) {
  .col {
    width: 100%;
    margin: 0 0 2rem 0;
  }
}

/* Responsive */

/* Mobile  */

@media (min-width: 200px) and (max-width: 374px) {
  .innertxt {
    font-size: 1rem;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .langjs {
    margin-left: -40%;
  }

  .langwhimsical {
    margin-left: -40%;
  }

  .langphpadmin {
    margin-left: -60%;
  }

  .innertxtjs {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 20%;
  }

  .innertxtcplusplus {
    margin-top: 35%;
  }

  .innertxtcsharp {
    margin-top: 35%;
  }

  .innertxtreact {
    margin-top: 15%;
  }

  .innertxtjava {
    margin-top: 30%;
  }

  .innertxtphp {
    margin-top: 25%;
  }

  .innertxtvs {
    margin-top: 10%;
  }

  .innertxtvscode {
    margin-top: 20%;
  }

  .innertxtandroid {
    margin-top: 10%;
  }

  .innertxtfigma {
    margin-top: 10%;
  }

  .innertxtwhimsical {
    margin-top: 10%;
  }

  .innertxtgit {
    margin-top: 10%;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .innertxt {
    font-size: 1.1rem;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .langjs {
    margin-left: -20%;
  }

  .langwhimsical {
    margin-left: -20%;
  }

  .langphpadmin {
    margin-left: -35%;
  }

  .innertxtjs {
    margin-top: 5%;
  }

  .innertxtcss {
    margin-top: 15%;
  }

  .innertxtcplusplus {
    margin-top: 28%;
  }

  .innertxtcsharp {
    margin-top: 25%;
  }

  .innertxtreact {
    margin-top: 10%;
  }

  .innertxtjava {
    margin-top: 25%;
  }

  .innertxtphp {
    margin-top: 20%;
  }

  .innertxtsql {
    margin-top: 5%;
  }

  .innertxtvs {
    margin-top: 10%;
  }

  .innertxtvscode {
    margin-top: 15%;
  }

  .innertxtandroid {
    margin-top: 8%;
  }

  .innertxtfigma {
    margin-top: 5%;
  }

  .innertxtwhimsical {
    margin-top: 5%;
  }

  .innertxtgit {
    margin-top: 5%;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }
}

@media (min-width: 375px) and (max-width: 410px) {
  .innertxt {
    font-size: 1.1rem;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .langjs {
    margin-left: -10%;
  }

  .langwhimsical {
    margin-left: -10%;
  }

  .langphpadmin {
    margin-left: -30%;
  }

  .innertxtjs {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 15%;
  }

  .innertxtcplusplus {
    margin-top: 28%;
  }

  .innertxtcsharp {
    margin-top: 25%;
  }

  .innertxtreact {
    margin-top: 15%;
  }

  .innertxtjava {
    margin-top: 25%;
  }

  .innertxtphp {
    margin-top: 20%;
  }

  .innertxtsql {
    margin-top: 5%;
  }

  .innertxtvs {
    margin-top: 15%;
  }

  .innertxtvscode {
    margin-top: 20%;
  }

  .innertxtandroid {
    margin-top: 8%;
  }

  .innertxtfigma {
    margin-top: 5%;
  }

  .innertxtwhimsical {
    margin-top: 10%;
  }

  .innertxtgit {
    margin-top: 5%;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }
}

@media (min-width: 411px) and (max-width: 500px) {
  .innertxt {
    font-size: 1.1rem;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .langjs {
    margin-left: 0%;
  }

  .langwhimsical {
    margin-left: 0%;
  }

  .langphpadmin {
    margin-left: -15%;
  }

  .innertxthtml {
    margin-top: 5%;
  }

  .innertxtjs {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 20%;
  }

  .innertxtcplusplus {
    margin-top: 28%;
  }

  .innertxtcsharp {
    margin-top: 25%;
  }

  .innertxtreact {
    margin-top: 15%;
  }

  .innertxtjava {
    margin-top: 25%;
  }

  .innertxtphp {
    margin-top: 20%;
  }

  .innertxtsql {
    margin-top: 5%;
  }

  .innertxtvs {
    margin-top: 15%;
  }

  .innertxtvscode {
    margin-top: 20%;
  }

  .innertxtandroid {
    margin-top: 10%;
  }

  .innertxtfigma {
    margin-top: 12%;
  }

  .innertxtwhimsical {
    margin-top: 15%;
  }

  .innertxtgit {
    margin-top: 8%;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .underline-skill1 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 225px 3px; /* Hauteur de votre soulignement */
    background-position: 50% 100%; /* Positionne le dégradé en bas */
    margin-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .underline-skill2 {
    background-image: linear-gradient(
      100deg,
      #f7086c 0%,
      #ba00ff 50%,
      #35c9fc 100%
    );
    background-size: 180px 3px; /* Hauteur de votre soulignement */
    background-position: 0 100%; /* Positionne le dégradé en bas */
    background-position-x: 50%;
    margin-top: 5px;
    margin-bottom: 25px;
    padding-bottom: 5px;
    background-repeat: no-repeat;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }

  .innertxt {
    font-size: large;
  }

  .innertxthtml {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 18%;
  }

  .innertxtjs {
    margin-top: 11%;
  }

  .innertxtcplusplus {
    margin-top: 26%;
  }

  .innertxtcsharp {
    margin-top: 26%;
  }

  .innertxtreact {
    margin-top: 15%;
  }

  .innertxtjava {
    margin-top: 22%;
  }

  .innertxtphp {
    margin-top: 18%;
  }

  .innertxtsql {
    margin-top: 3%;
  }

  .innertxtvs {
    margin-top: 15%;
  }

  .innertxtvscode {
    margin-top: 20%;
  }

  .innertxtandroid {
    margin-top: 11%;
  }

  .innertxtfigma {
    margin-top: 12%;
  }

  .innertxtwhimsical {
    margin-top: 15%;
  }

  .innertxtgit {
    margin-top: 8%;
  }
}

@media (min-width: 513px) and (max-width: 767px) {
  .innertxt {
    font-size: 0.8rem;
  }

  .innertxthtml {
    margin-top: 15%;
  }

  .innertxtcss {
    margin-top: 35%;
  }

  .innertxtjs {
    margin-top: 28%;
  }

  .innertxtcplusplus {
    margin-top: 50%;
  }

  .innertxtcsharp {
    margin-top: 50%;
  }

  .innertxtreact {
    margin-top: 30%;
  }

  .innertxtjava {
    margin-top: 44%;
  }

  .innertxtphp {
    margin-top: 36%;
  }

  .innertxtsql {
    margin-top: 10%;
  }

  .innertxtvs {
    margin-top: 25%;
  }

  .innertxtvscode {
    margin-top: 35%;
  }

  .innertxtandroid {
    margin-top: 30%;
  }

  .innertxtfigma {
    margin-top: 27%;
  }

  .innertxtwhimsical {
    margin-top: 27%;
  }

  .innertxtgit {
    margin-top: 20%;
  }

  .innertxtmysql {
    margin-top: 15%;
  }

  .innertxtphpadmin {
    margin-top: 10%;
  }

  .innertxtqt {
    margin-top: 2%;
  }

  .front .inner p:after {
    content: "";
    width: 2rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .front .inner p {
    text-align: center;
    font-size: 1rem;
    width: auto;
    position: relative;
  }
}

@media (min-width: 513px) and (max-width: 570px) {
  .langjs {
    right: 1.5rem;
  }

  .front .innerjs p:after {
    left: 1.5rem;
  }

  .langhtml {
    right: 0.5rem;
  }

  .front .innerhtml p:after {
    left: 0.5rem;
  }

  .langreact {
    right: 0.5rem;
  }

  .front .innerreact p:after {
    left: 0.5rem;
  }

  .langvs {
    right: 0.5rem;
  }

  .front .innervs p:after {
    left: 0.3rem;
  }

  .langandroid {
    right: 0.8rem;
  }

  .front .innerandroid p:after {
    left: 0.7rem;
  }

  .langfigma {
    right: 0.5rem;
  }

  .front .innerfigma p:after {
    left: 0.5rem;
  }

  .langwhimsical {
    right: 1.2rem;
  }

  .front .innerwhimsical p:after {
    left: 1.4rem;
  }

  .langmysql {
    right: 0.5rem;
  }

  .front .innermysql p:after {
    left: 0.5rem;
  }

  .langphpadmin {
    right: 1.9rem;
  }

  .front .innerphpadmin p:after {
    left: 2rem;
  }

  .langqt {
    right: 0.7rem;
  }

  .front .innerqt p:after {
    left: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .underline-skill1 {
    background-size: 185px 3px;
  }

  .underline-skill2 {
    background-size: 150px 3px;
  }

  .front .inner p:after {
    width: 3rem;
  }

  .front .inner p {
    text-align: center;
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .innertxt {
    font-size: 1.2rem;
  }

  .innertxthtml {
    margin-top: 3%;
  }

  .innertxtcss {
    margin-top: 25%;
  }

  .innertxtjs {
    margin-top: 18%;
  }

  .innertxtcplusplus {
    margin-top: 40%;
  }

  .innertxtcsharp {
    margin-top: 35%;
  }

  .innertxtreact {
    margin-top: 20%;
  }

  .innertxtjava {
    margin-top: 32%;
  }

  .innertxtphp {
    margin-top: 25%;
  }

  .innertxtsql {
    margin-top: 3%;
  }

  .innertxtvs {
    margin-top: 15%;
  }

  .innertxtvscode {
    margin-top: 25%;
  }

  .innertxtandroid {
    margin-top: 12%;
  }

  .innertxtfigma {
    margin-top: 15%;
  }

  .innertxtwhimsical {
    margin-top: 17%;
  }

  .innertxtgit {
    margin-top: 12%;
  }

  .innertxtmysql {
    margin-top: 3%;
  }

  .innertxtphpadmin {
    margin-top: 3%;
  }

  .front .innerphpadmin p:after {
    left: 3rem;
  }

  .langphpadmin {
    right: 1.6rem;
  }

  .langjs {
    right: 0.8rem;
  }

  .front .innerjs p:after {
    left: 1.5rem;
  }
}

@media (width: 768px) {
  .underline-skill1 {
    background-size: 244px 3px;
    font-size: 2rem;
  }

  .underline-skill2 {
    background-size: 195px 3px;
    font-size: 2rem;
  }

  .innertxt {
    font-size: 1.4rem;
  }

  .front .inner p {
    font-size: 1.5rem;
    width: auto;
    position: relative;
  }

  .front .inner p:after {
    content: "";
    width: 4rem;
    height: 2px;
    position: absolute;
    background: linear-gradient(45deg, #f7086c 0%, #35c9fc 100%);
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -0.75rem;
  }

  .front .inner p {
    text-align: center;
    font-size: 2.2rem;
    width: auto;
    position: relative;
  }

  .langjs {
    margin-left: -10%;
  }

  .langwhimsical {
    margin-left: -10%;
  }

  .langphpadmin {
    margin-left: -26%;
  }

  .innertxtjs {
    margin-top: 8%;
  }

  .innertxtcss {
    margin-top: 15%;
  }

  .innertxtcplusplus {
    margin-top: 28%;
  }

  .innertxtcsharp {
    margin-top: 25%;
  }

  .innertxtreact {
    margin-top: 10%;
  }

  .innertxtjava {
    margin-top: 25%;
  }

  .innertxtphp {
    margin-top: 20%;
  }

  .innertxtsql {
    margin-top: 5%;
  }

  .innertxtvs {
    margin-top: 15%;
  }

  .innertxtvscode {
    margin-top: 15%;
  }

  .innertxtandroid {
    margin-top: 8%;
  }

  .innertxtfigma {
    margin-top: 5%;
  }

  .innertxtwhimsical {
    margin-top: 5%;
  }

  .innertxtgit {
    margin-top: 5%;
  }

  .txtPC {
    display: none;
  }

  .txtMobile {
    display: block;
  }
}

/* Tablette vertical */

@media (min-width: 769px) and (max-width: 1023px) {
  .underline-skill1 {
    background-size: 185px 3px;
  }

  .underline-skill2 {
    background-size: 150px 3px;
  }

  .front .inner p {
    text-align: center;
    font-size: 1.4rem;
    width: auto;
    position: relative;
  }

  .innertxt {
    font-size: 1.1rem;
  }

  .innertxthtml {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 25%;
  }

  .innertxtjs {
    margin-top: 20%;
  }

  .innertxtcplusplus {
    margin-top: 45%;
  }

  .innertxtcsharp {
    margin-top: 40%;
  }

  .innertxtreact {
    margin-top: 25%;
  }

  .innertxtjava {
    margin-top: 37%;
  }

  .innertxtphp {
    margin-top: 30%;
  }

  .innertxtsql {
    margin-top: 3%;
  }

  .innertxtvs {
    margin-top: 20%;
  }

  .innertxtvscode {
    margin-top: 30%;
  }

  .innertxtandroid {
    margin-top: 20%;
  }

  .innertxtfigma {
    margin-top: 15%;
  }

  .innertxtwhimsical {
    margin-top: 20%;
  }

  .innertxtgit {
    margin-top: 15%;
  }

  .innertxtmysql {
    margin-top: 3%;
  }

  .innertxtphpadmin {
    margin-top: 3%;
  }

  .innertxtqt {
    margin-top: 2%;
  }

  .langjs {
    right: 1.8rem;
  }

  .front .innerjs p:after {
    left: 1.5rem;
  }

  .langandroid {
    right: 0.2rem;
  }

  .langwhimsical {
    right: 1.5rem;
  }

  .front .innerwhimsical p:after {
    left: 1.2rem;
  }

  .langmysql {
    right: 0.7rem;
  }

  .langphpadmin {
    right: 2.5rem;
  }

  .front .innerphpadmin p:after {
    left: 2rem;
  }

  .langqt {
    right: 0.8rem;
  }
}

@media (min-width: 915px) and (max-width: 1023px) {
  .innertxt {
    font-size: 1.2rem;
  }

  .langjs {
    right: 0.4rem;
  }

  .front .innerjs p:after {
    left: 1.5rem;
  }

  .langwhimsical {
    right: 0.4rem;
  }

  .langmysql {
    right: 0rem;
  }

  .langphpadmin {
    right: 1.4rem;
  }

  .langqt {
    right: 0rem;
  }
}

/* Tablette Horizontal */

@media (width: 1024px) {
  .underline-skill1 {
    background-size: 180px 3px;
  }

  .underline-skill2 {
    background-size: 150px 3px;
  }

  .front .inner p:after {
    width: 3rem;
  }

  .front .inner p {
    text-align: center;
    font-size: 1.6rem;
    width: auto;
    position: relative;
  }

  .innertxt {
    font-size: 1.2rem;
  }

  .innertxthtml {
    margin-top: 11%;
  }

  .innertxtcss {
    margin-top: 22%;
  }

  .innertxtjs {
    margin-top: 18%;
  }

  .innertxtcplusplus {
    margin-top: 38%;
  }

  .innertxtcsharp {
    margin-top: 35%;
  }

  .innertxtreact {
    margin-top: 22%;
  }

  .innertxtjava {
    margin-top: 35%;
  }

  .innertxtphp {
    margin-top: 30%;
  }

  .innertxtsql {
    margin-top: 4%;
  }

  .innertxtvs {
    margin-top: 18%;
  }

  .innertxtvscode {
    margin-top: 30%;
  }

  .innertxtandroid {
    margin-top: 18%;
  }

  .innertxtfigma {
    margin-top: 15%;
  }

  .innertxtwhimsical {
    margin-top: 15%;
  }

  .innertxtgit {
    margin-top: 13%;
  }

  .innertxtmysql {
    margin-top: 7%;
  }

  .innertxtphpadmin {
    margin-top: 3%;
  }

  .innertxtqt {
    margin-top: 2%;
  }

  .langjs {
    right: 1.2rem;
  }

  .front .innerjs p:after {
    left: 2.5rem;
  }

  .langwhimsical {
    right: 1rem;
  }

  .front .innerwhimsical p:after {
    left: 2.5rem;
  }

  .langmysql {
    right: 0rem;
  }

  .langphpadmin {
    right: 1.9rem;
  }

  .front .innerphpadmin p:after {
    left: 3.2rem;
  }

  .langages,
  .outils {
    width: 100%;
    display: block;
    height: 95rem;
  }
}

@media (min-width: 1025px) and (max-width: 1599px) {
  .underline-skill1 {
    background-size: 230px 3px;
  }

  .underline-skill2 {
    background-size: 185px 3px;
  }

  .front .inner p:after {
    width: 3rem;
  }

  .front .inner p {
    text-align: center;
    font-size: 1.6rem;
    width: auto;
    position: relative;
  }

  .innertxt {
    font-size: 1.1rem;
  }

  .innertxthtml {
    margin-top: 10%;
  }

  .innertxtcss {
    margin-top: 20%;
  }

  .innertxtjs {
    margin-top: 18%;
  }

  .innertxtcplusplus {
    margin-top: 25%;
  }

  .innertxtcsharp {
    margin-top: 25%;
  }

  .innertxtreact {
    margin-top: 17%;
  }

  .innertxtjava {
    margin-top: 25%;
  }

  .innertxtphp {
    margin-top: 20%;
  }

  .innertxtsql {
    margin-top: 4%;
  }

  .innertxtvs {
    margin-top: 18%;
  }

  .innertxtvscode {
    margin-top: 20%;
  }

  .innertxtandroid {
    margin-top: 10%;
  }

  .innertxtfigma {
    margin-top: 15%;
  }

  .innertxtwhimsical {
    margin-top: 17%;
  }

  .innertxtgit {
    margin-top: 13%;
  }

  .innertxtmysql {
    margin-top: 7%;
  }

  .innertxtphpadmin {
    margin-top: 3%;
  }

  .innertxtqt {
    margin-top: 2%;
  }

  .langjs {
    right: 1.2rem;
  }

  .front .innerjs p:after {
    left: 2.5rem;
  }

  .langwhimsical {
    right: 1rem;
  }

  .front .innerwhimsical p:after {
    left: 2.5rem;
  }

  .langmysql {
    right: 0rem;
  }

  .langphpadmin {
    right: 1.9rem;
  }

  .front .innerphpadmin p:after {
    left: 3.2rem;
  }

  .langqt {
    right: 0rem;
  }
}

@media (min-width: 1180px) and (max-width: 1599px) {
  .langjs {
    right: 0.7rem;
  }

  .front .innerjs p:after {
    left: 1.5rem;
  }

  .langwhimsical {
    right: 0.3rem;
  }

  .front .innerwhimsical p:after {
    left: 1.2rem;
  }

  .langphpadmin {
    right: 1.5rem;
  }

  .front .innerphpadmin p:after {
    left: 3.2rem;
  }
}

@media (min-width: 1375px) and (max-width: 1599px) {
  .langjs {
    right: 0.2rem;
  }

  .front .innerjs p:after {
    left: 0.8rem;
  }

  .langphpadmin {
    right: 0.7rem;
  }

  .front .innerphpadmin p:after {
    left: 2.1rem;
  }
}
