@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mainproject {
  display: flex;
  margin-top: 2%;
}

.cardproject {
  position: relative;
  margin: auto;
  width: 550px;
  height: 700px;
  background-color: #0d1021;
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  color: white;
}

.overflowcard {
  overflow: auto;
}

.cardproject::before {
  content: "";
  position: absolute;
  inset: 0;
  left: -5px;
  margin: auto;
  width: 540px;
  height: 700px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.cardproject::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

.heading {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
}

.cardproject:hover::after {
  filter: blur(30px);
}

.cardproject:hover::before {
  transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
}

.dark-mode .cardproject {
  background-color: #0d1021;
  color: white;
}

.light-mode .cardproject {
  background-color: #ffffff;
  color: black;
}

/* In card */

.easybee {
  width: 400px;
}

.logoPortfolio {
  width: 135px;
  margin-left: 28%;
}

.espaceInCard {
  margin-bottom: 15px; /* Ajustez la valeur selon vos préférences */
}

.description {
  margin-top: 7px;
  margin-bottom: 25px;
}

.largeTitre {
  margin-bottom: 10px;
}

.lienSpace {
  margin-top: 13px;
  margin-bottom: 13px;
}

.btnGit {
  margin-right: 40%;
}

.btnGitTrello {
  margin-left: 7%;
  display: flex;
  flex-direction: row;
}

.detailCard {
  display: flex;
  margin-left: 23%;
}

.detailCardPort .btnPort {
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}

.detailCardPort button {
  display: inline-block;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  border: 0;
  background-color: #fff;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1.01rem;
  cursor: pointer;
  text-align: center;
}

.detailCardPort button:hover {
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
  background: linear-gradient(
    90deg,
    rgba(255, 95, 109, 1) 0%,
    rgba(53, 201, 252, 1) 100%
  );
  transition: all 0.3s ease-in-out;
}

.dark-mode .detailCardPort button {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
}

.detailCard .btn {
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}

.detailCard button {
  display: inline-block;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  border: 0;
  background-color: #fff;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1.01rem;
  cursor: pointer;
  text-align: center;
}

.detailCard button:hover {
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
  background: linear-gradient(
    90deg,
    rgba(255, 95, 109, 1) 0%,
    rgba(53, 201, 252, 1) 100%
  );
  transition: all 0.3s ease-in-out;
}

.dark-mode .detailCard button {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
}

.btnPort {
  margin-left: 37%;
}

.btnGit {
  margin-top: 5px;
  margin-left: 10%;
}

.btnGit a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  border-radius: 60%;
  padding: 0.15rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.btnGit a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.btnGitIn {
  margin-top: 5px;
  margin-left: 10%;
}

.btnGitIn a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  border-radius: 60%;
  padding: 0.15rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.btnGitIn a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.btnTrello {
  margin-top: 5px;
  margin-left: 5%;
}

.btnTrello a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  border-radius: 60%;
  padding: 0.15rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.btnTrello a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.btnTrelloIn {
  margin-top: 5px;
  margin-left: 5%;
}

.btnTrelloIn a {
  color: #000;
  -webkit-text-fill-color: transparent;
  border: none;
  display: grid;
  place-items: center;
  text-decoration: none;
  font-size: 1.8rem;
  border-radius: 60%;
  padding: 0.15rem;
  text-align: center;
  opacity: 0;
  animation: SocialAni 1s ease forwards;
  animation-delay: calc(0.2s * var(--socialAni));
  background-clip: padding-box;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
}

.btnTrelloIn a::before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  border-radius: inherit;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  z-index: -1;
}

.btnPlusDeDetail {
  color: #0d1021;
}

.btnPlusDeDetail button {
  color: #0d1021;
}

/* Popup */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  height: 850px;
  width: 1000px;
  border: 3px solid #0d1021;
  z-index: 999;
  overflow: auto;
}

.dark-mode .popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0d1021;
  border: 3px solid #ccc;
  z-index: 999;
}

.popup:not(.visible) {
  display: none;
}

.popupContent {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
}

.dark-mode .popupContent {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #0d1021;
  z-index: 100;
  box-shadow: 0 4px 17px rgb(80 80 80 / 31%);
}

.projectName {
  margin-left: 42%;
}

.projectNamePort {
  margin-left: 45%;
}

.btnFermer {
  margin-left: auto;
  font-size: 2.5em;
  margin-right: 15px;
}

.texteprojets {
  text-align: left;
  font-size: 19px;
  margin-left: 30px;
  margin-right: 15px;
}

.popup h1 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.popup h4 {
  margin-left: 30px;
  text-align: left;
}

.btnPopup {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
  margin-left: 40%;
}

.popup .btn {
  margin-top: 15px;
  margin-left: 45%;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  padding: 1px;
  opacity: 0;
  animation: btn-ani 1s ease forwards;
  animation-delay: 0.4s;
}

.popup button {
  display: inline-block;
  height: 42px;
  width: 132px;
  border-radius: 36px;
  border: 0;
  background-color: #fff;
  font-family: var(--primary-font);
  font-weight: 600;
  font-size: 1.01rem;
  cursor: pointer;
  text-align: center;
}

.popup button:hover {
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
  background: linear-gradient(
    90deg,
    rgba(255, 95, 109, 1) 0%,
    rgba(53, 201, 252, 1) 100%
  );
  transition: all 0.3s ease-in-out;
}

.dark-mode .popup button {
  background: linear-gradient(100deg, #f7086c 10%, #ba00ff 50%, #35c9fc 90%);
  -webkit-background-clip: text;
  background-clip: initial;
  -webkit-text-fill-color: var(--primary-dark);
}

/* img popop  */

.imgPopup {
  width: 940px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.imgTelAp4 {
  width: 370px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.imgMargTop {
  margin-top: 15px;
}

.imgMargBot {
  margin-bottom: 15px;
}

.imgScreenAp2 {
  width: 500px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* End Popup */

.smallTitre {
  display: flex;
  margin-left: 18px;
  width: 86%;
  cursor: pointer;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(247, 8, 108, 0.8), 0 0 20px rgba(53, 201, 252, 0.8);
}

.btnDeroule {
  margin-left: auto;
  border: none;
  color: #000000;
}

.dark-mode .btnDeroule {
  border: none;
  color: #ffffff;
}

.deroule {
  display: none;
  margin-top: 15px;
  margin-bottom: 15px;
}

.deroule.visible {
  display: block;
}

.listeproject {
  margin-left: 10%;
  text-align: left;
}

/* ScrollBar */

/* Barre de défilement pour les navigateurs WebKit */
::-webkit-scrollbar {
  width: 2px; /* ajustez la largeur selon vos préférences */
  height: 0px;
}

/* Styles pour la piste de la barre de défilement */
.dark-mode ::-webkit-scrollbar-track {
  background-color: #0d1021; /* couleur de fond de la piste */
}

/* Styles pour la poignée/griffe de la barre de défilement */
.dark-mode ::-webkit-scrollbar-thumb {
  background-color: #888; /* couleur de la poignée/griffe */
  border-radius: 4px; /* arrondi des coins de la poignée/griffe */
}

/* Styles pour la piste de la barre de défilement */
::-webkit-scrollbar-track {
  background-color: #ffffff; /* couleur de fond de la piste */
}

/* Styles pour la poignée/griffe de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-color: #0d1021; /* couleur de la poignée/griffe */
  border-radius: 4px; /* arrondi des coins de la poignée/griffe */
}

/* Styles pour la flèche bouton de la barre de défilement (optionnel) */
::-webkit-scrollbar-button {
  display: none; /* masquer le bouton si vous le souhaitez */
}

/* Responsive */

/* Mobile */

@media (min-width: 200px) and (max-width: 359px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .logoPortfolio {
    margin-left: 1rem;
    width: 5rem;
  }

  .easybee {
    width: 10rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 9.5rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 7rem;
  }

  .detailCard {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }

  .btnGitTrello {
    margin-right: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 600px;
    width: 65%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .btnPopup {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .projectName {
    margin-left: 25%;
  }

  .smallTitre {
    width: 6rem;
  }

  .lienSpace {
    font-size: 1rem;
  }

  .largeTitre {
    font-size: 1.1rem;
  }

  .descriptionTitle {
    font-size: 1.05rem;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media (min-width: 360px) and (max-width: 374px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .logoPortfolio {
    margin-left: 1.5rem;
    width: 6rem;
  }

  .easybee {
    width: 10rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 12rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 7rem;
  }

  .detailCard {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }

  .btnGitTrello {
    margin-right: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 600px;
    width: 65%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .btnPopup {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .projectName {
    margin-left: 25%;
  }

  .smallTitre {
    width: 7rem;
  }

  .lienSpace {
    font-size: 1rem;
  }

  .largeTitre {
    font-size: 1.1rem;
  }

  .descriptionTitle {
    font-size: 1.05rem;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media (min-width: 412px) and (max-width: 413px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .logoPortfolio {
    margin-left: 3rem;
    width: 8rem;
  }

  .easybee {
    width: 20rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 20rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 4rem;
  }

  .detailCard {
    margin-left: 6rem;
    display: flex;
    flex-direction: column;
  }

  .btnGitTrello {
    margin-right: 65%;
    display: flex;
    flex-direction: row;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 600px;
    width: 65%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .btnPopup {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .projectName {
    margin-left: 25%;
  }

  .smallTitre {
    width: 15rem;
  }

  .lienSpace {
    font-size: 1rem;
  }

  .largeTitre {
    font-size: 1.5rem;
  }

  .descriptionTitle {
    font-size: 1.2rem;
  }

  .description {
    font-size: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 411px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .logoPortfolio {
    margin-left: 1.5rem;
    width: 6rem;
  }

  .easybee {
    width: 10rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 13rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 7rem;
  }

  .detailCard {
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;
  }

  .btnGitTrello {
    margin-right: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 600px;
    width: 65%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .btnPopup {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .projectName {
    margin-left: 25%;
  }

  .smallTitre {
    width: 8.5rem;
  }

  .lienSpace {
    font-size: 1rem;
  }

  .largeTitre {
    font-size: 1.1rem;
  }

  .descriptionTitle {
    font-size: 1.05rem;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media (min-width: 414px) and (max-width: 500px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .logoPortfolio {
    margin-left: 2rem;
    width: 7rem;
  }

  .easybee {
    width: 15rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 15.5rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 7rem;
  }

  .detailCard {
    margin-left: 3.5rem;
    display: flex;
    flex-direction: column;
  }

  .btnGitTrello {
    margin-right: 35%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 600px;
    width: 65%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 35%;
    left: 38%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .btnPopup {
    margin-left: 0;
    justify-content: space-evenly;
  }

  .projectName {
    margin-left: 25%;
  }

  .smallTitre {
    width: 10rem;
  }

  .lienSpace {
    font-size: 1.1rem;
  }

  .largeTitre {
    font-size: 1.5rem;
  }

  .descriptionTitle {
    font-size: 1.1rem;
  }

  .description {
    font-size: 0.9rem;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .btnGit {
    margin-right: 50%;
  }

  .btnGitTrello {
    margin-left: 7%;
    display: flex;
    flex-direction: row;
  }

  .logoPortfolio {
    margin-left: 3rem;
  }

  .easybee {
    width: 20rem;
  }

  .cardproject {
    height: 49.8rem;
    max-width: 20.625rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .projectName {
    margin-left: 8rem;
  }

  .detailCardPort {
    margin-right: 4rem;
  }

  .detailCard {
    margin-left: 2.5rem;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 755px;
    width: 100%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .smallTitre {
    width: 16rem;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .btnPort button {
    font-size: 1.2rem;
  }

  .btnPlusDeDetail button {
    font-size: 1.2rem;
  }

  .logoPortfolio {
    margin-left: 4.5rem;
    width: 10rem;
  }

  .easybee {
    width: 27rem;
  }

  .projectName {
    margin-left: 11rem;
  }

  .cardproject {
    height: 54rem;
    max-width: 30rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .detailCardPort {
    margin-right: 3rem;
  }

  .detailCard {
    margin-left: 5rem;
  }

  .cardproject::before {
    content: "";
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 0px;
    height: 700px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #f7086c 0%, #35c9fc 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 44%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    height: 755px;
    width: 100%;
    max-width: 400px; /* ajustez la largeur maximale selon vos préférences */
    border: 3px solid #0d1021;
    z-index: 999;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 44%;
    transform: translate(-50%, -50%);
    background-color: #0d1021;
    border: 3px solid #ccc;
    z-index: 999;
  }

  .smallTitre {
    width: 23.5rem;
  }

  .listeproject {
    font-size: 1.1rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .largeTitre {
    font-size: 1.5rem;
  }

  .smallTitre {
    font-size: 1.1rem;
  }

  .lienSpace {
    font-size: 1.3rem;
  }
}

/* Tablette vertical */

@media (width: 768px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .cardproject {
    height: 55rem;
    max-width: 50rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .cardproject::before {
    width: 540px;
    height: 680px;
  }

  .cardproject:hover::before {
    transform: rotate(-90deg) scaleX(1.25) scaleY(0.77);
  }

  .description {
    font-size: 1.2rem;
  }

  .largeTitre {
    font-size: 2rem;
  }

  .btnPort button {
    font-size: 1.3rem;
  }

  .btnPlusDeDetail button {
    font-size: 1.3rem;
  }

  .smallTitre {
    font-size: 1.2rem;
  }

  .lienSpace {
    font-size: 1.5rem;
  }

  .listeproject {
    font-size: 1.2rem;
  }

  .popup {
    position: fixed;
    top: 40%;
    left: 46.5%;
    height: 755px;
    width: 70%;
    max-width: 920px;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 40%;
    left: 46.5%;
  }

  .btnGit {
    margin-right: 50%;
  }

  .btnGitTrello {
    margin-left: 7%;
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .btnGit {
    margin-right: 50%;
  }

  .btnGitTrello {
    margin-left: 7%;
    display: flex;
    flex-direction: row;
  }

  .cardproject {
    height: 55rem;
    max-width: 50rem;
    margin-bottom: 10%;
    margin-top: 10%;
  }

  .cardproject::before {
    width: 540px;
    height: 680px;
  }

  .cardproject:hover::before {
    transform: rotate(-90deg) scaleX(1.25) scaleY(0.77);
  }

  .description {
    font-size: 1.2rem;
  }

  .largeTitre {
    font-size: 2rem;
  }

  .btnPort button {
    font-size: 1.3rem;
  }

  .btnPlusDeDetail button {
    font-size: 1.3rem;
  }

  .smallTitre {
    font-size: 1.2rem;
  }

  .lienSpace {
    font-size: 1.5rem;
  }

  .listeproject {
    font-size: 1.2rem;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 47%;
    height: 650px;
    width: 85%;
    max-width: 920px;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 47%;
  }
}

@media (width: 1024px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .btnGit {
    margin-right: 50%;
  }

  .btnGitTrello {
    margin-left: 7%;
    display: flex;
    flex-direction: row;
  }

  .cardproject {
    height: 52rem;
    max-width: 50rem;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .cardproject::before {
    width: 540px;
    height: 680px;
  }

  .cardproject:hover::before {
    transform: rotate(-90deg) scaleX(1.25) scaleY(0.77);
  }

  .description {
    font-size: 1.05rem;
  }

  .largeTitre {
    font-size: 1.5rem;
  }

  .btnPort button {
    font-size: 1.05rem;
  }

  .btnPlusDeDetail button {
    font-size: 1.05rem;
  }

  .smallTitre {
    font-size: 1rem;
  }

  .lienSpace {
    font-size: 1.2rem;
  }

  .listeproject {
    font-size: 1.1rem;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 1000px;
    width: 100%;
    max-width: 920px;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

/* Tablette Horizontal */

@media (min-width: 1025px) and (max-width: 1599px) {
  .mainproject {
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .cardproject {
    height: 45.5rem;
    max-width: 50rem;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  .cardproject::before {
    width: 540px;
    height: 680px;
  }

  .cardproject:hover::before {
    transform: rotate(-90deg) scaleX(1.25) scaleY(0.77);
  }

  .description {
    font-size: 1.05rem;
  }

  .largeTitre {
    font-size: 1.5rem;
  }

  .btnPort button {
    font-size: 1.05rem;
  }

  .btnPlusDeDetail button {
    font-size: 1.05rem;
  }

  .smallTitre {
    font-size: 1rem;
  }

  .lienSpace {
    font-size: 1.2rem;
  }

  .listeproject {
    font-size: 1.1rem;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    height: 755px;
    width: 100%;
    max-width: 920px;
    overflow: auto;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

@media (min-width: 1366px) and (max-width: 1599px) {
  .popup {
    position: fixed;
    top: 50%;
    left: 46.5%;
    height: 700px;
    width: 100%;
    max-width: 1000px;
    overflow: auto;
    margin-left: 5%;
  }

  .dark-mode .popup {
    position: fixed;
    top: 50%;
    left: 46.5%;
  }
}
